import React from "react"
import { graphql, useStaticQuery } from "gatsby"
import {Helmet} from "react-helmet"
import LocaleContext from "../localeContext"
import SharedLogo from "../assets/sharedLogo/share_logo(3c).png"

const SEO = ({ title, description, pageContext, image }) => {
  const { locale } = React.useContext(LocaleContext)

  const { site } = useStaticQuery(
    graphql`
      query {
        site {
          siteMetadata {
            siteUrl
            supportedLanguages
            description
            title
          }
        }
      }
    `,
  )
  const { lang, originalPath } = pageContext
  const host = site.siteMetadata.siteUrl
  return (
      <Helmet
        htmlAttributes={{
          lang,
        }}
        title={title}
        meta={[
          {
            property: "description",
            content: description,
          },
          {
            property: "og:title",
            content: title,
          },
          {
            property: "title",
            content: title,
          },
          {
            property: "og:description",
            content: description,
          },
          {
            property: "og:locale",
            content: locale,
          },
          {
            property: "og:image",
            content: image ? image : SharedLogo
          },
          {
            property: "image",
            content: image ? image : SharedLogo
          },
        ]/* .concat(meta) */}
        link={[
          {
            rel: "canonical",
            href: `${host}/${locale}${originalPath}`,
          },
          {
            rel: "alternate",
            hrefLang: "x-default",
            href: `${host}${originalPath}`,
          },
          ...site.siteMetadata.supportedLanguages.map(supportedLang => ({
            rel: "alternate",
            hrefLang: supportedLang,
            href: `${host}/${supportedLang}${originalPath}`,
          })),
        ]}
      >
        <script async src="https://www.googletagmanager.com/gtag/js?id=AW-10869332556" />
        <script async src="https://www.googletagmanager.com/gtag/js?id=G-FQ1MP5J8SM"></script>
        <script defer>
          {`
          window.dataLayer = window.dataLayer || [];
          function gtag(){dataLayer.push(arguments);}
          gtag('js', new Date());
          gtag('config', 'AW-10869332556'); 
        `}
        </script>
        <script defer>
          {`
          window.dataLayer = window.dataLayer || [];
          function gtag(){window.dataLayer.push(arguments);}
          gtag('js', new Date());
          gtag('config', 'G-FQ1MP5J8SM');
        `}
        </script>
      </Helmet>
  )
}

export default SEO
